import { constants } from '../core/_constantsRedux/constants'

const initialState = {
pages:[],
activitiesDisplay:null,
activityDisplay:null,
// activitiesUndisplay:null,
activitiesClosed:null,
activityClosed:null,
page:null,
accreditation:null,
status:null,
pathNavigate:null,
err:null
}

export default function pagesReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_PAGES:
            return {
                ...state,
                pages:action.payload.pages,
                pathNavigate:action.payload.path,
                err: null, // Réinitialiser l'erreur

            }
        case constants.GET_PAGES_ERR:
            return {
                ...state,
                err: action.payload
            }
        // case constants.POST_PAGES:
        //     return {
        //         ...state,
        //         pages:[...action.payload.pages],
        //         err: null, // Réinitialiser l'erreur
        //     }
        case constants.POST_PAGES_ERR:
            return {
                ...state,
                err: action.payload
            }
        case constants.GET_PAGE:
            return {
                ...state,
                page:action.payload.page,
                err: null, // Réinitialiser l'erreur
            }
        case constants.GET_PAGE_ERR:
            return {
                ...state,
                err: action.payload
            }
        case constants.GET_PAGE_ACTIVITIES:
            return {
                ...state,
                activitiesDisplay:action.payload
            }
        case constants.GET_PAGE_ACTIVITIES_ERR:
            return {
                ...state,
                err: action.payload
            }
        case constants.GET_PAGE_ACTIVITY:
            return {
                ...state,
                activityDisplay:action.payload
            }
        case constants.GET_PAGE_ACTIVITY_ERR:
            return {
                ...state,
                err: action.payload
            }
        case constants.GET_PAGE_ACTIVITIES_UNDISPLAY:
                return {
                    ...state,
                    activitiesUndisplay:action.payload
            }
        case constants.GET_PAGE_ACTIVITIES_UNDISPLAY_ERR:
            return {
                ...state,
                err:action.payload
            }
        case constants.GET_PAGE_ACTIVITIES_CLOSED:
                return {
                    ...state,
                    activitiesClosed:action.payload
            }
        case constants.GET_PAGE_ACTIVITIES_CLOSED_ERR:
                return {
                    ...state,
                    err:action.payload
            }
        case constants.GET_PAGE_ACTIVITY_CLOSED:
            return {
                ...state,
                activityClosed:action.payload
        }
        case constants.GET_ACCREDITATION:
            return {
                ...state,
                accreditation:action.payload
            }
        case constants.GET_ACCREDITATION_ERR:
            return {
                ...state,
                err: action.payload
            }
        case constants.GET_Navigate:
            return {
                ...state,
                pathNavigate:action.payload.pathNavigate
            }
        case constants.GET_Navigate_ERR:
            return {
                ...state,
                err: action.payload
            }
        default:
        return state
    }
}