
export const ENV = process.env.NODE_ENV;

export const I_KIDDY = "i-kiddy";
export const ACCESS_TOKEN_NAME = "access_token";
export const REFRESH_TOKEN_NAME = "refresh_token";
export const ACCESS_EXPIRES_IN = "access-expires-in";
export const REFRESH_EXPIRES_IN = "refresh-expires-in";
export const EXPIRY_TIME_NAME = "expiry-time-name"
export const AVATAR = "avatar";
export const EMAIL = "email";
export const FIRSTNAME = "firstname";
export const LASTNAME = "lastname";
export const ID_USER = "id";

export const PUBLIC_URL = process.env.PUBLIC_URL;

export const ENDPOINT_HTTP = process.env.REACT_APP_SERVER_DOMAIN;
export const NO_AVATAR_URL = "/assets/images/no_avatar.png";

export const ONE_MINUTE = 60000;
export const FIVE_MINUTES = 300000;
export const FIFTEEN_MINUTES = 900000;
export const THIRTY_MINUTES = 1800000;
export const ONE_HOUR = 3600000;
export const ONE_DAY = 86400000;

export const MAX_SNACK = 5;

export const languages = ["fr", "nl", "en"];
export const SNACKBAR_EVENT = "new-snackbar";


