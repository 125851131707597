import { constants } from '../core/_constantsRedux/constants'

const initialState = {
type:null,
message:null,
err:null
}

export default function alertReducer(state = initialState, action) {
    switch (action.type) {
        case constants.ALERT_INFO:
        return {
            ...state,
            type: 'info',
            message: action.payload.message,
            err: null, // Réinitialiser l'erreur
        };
        case constants.ALERT_SUCCESS:
        return {
            ...state,
            type: 'success',
            message: action.payload.message,
            err: null, // Réinitialiser l'erreur
        };
        case constants.ALERT_WARNING:
        return {
            ...state,
            type: 'warning',
            message: action.payload.message,
            err: null, // Réinitialiser l'erreur
        };
        case constants.ALERT_QUEUE:
        return {
            ...state,
            type: 'queue',
            data: action.payload.data,
            message: action.payload.message,
            err: null, // Réinitialiser l'erreur
        };
        case constants.ALERT_ERROR:
        return {
            ...state,
            type: 'error',
            message: action.payload.message
        };
        case constants.ALERT_CLEAR:
        return initialState
        default:
        return state
    }
}