
import { constants } from '../core/_constantsRedux/constants'

const initialState = {
    users:null,
    kids:null,
    chronos:null,
    pages:null,
    factures:null,
    activities:null,
    discipline:null,
    page:null,
    size:null,
    amount:null,
    status:true,
    err:null
}
export default function adminReducer(state = initialState, action){
    switch (action.type) {
        case constants.GET_USERS:
            return {
                ...state,
                users:action.payload.users,
                page:action.payload.page,
                size:action.payload.size,
                amount:action.payload.amount,
                status:true,
                err: null, // Réinitialiser l'erreur
            } 
        case constants.GET_KIDS:
        return {
            ...state,
            kids:action.payload.kids,
            page:action.payload.page,
            size:action.payload.size,
            amount:action.payload.amount,
            status:true,
            err: null, // Réinitialiser l'erreur
        } 
        case constants.GET_CHRONOS:
        return {
            ...state,
            chronos:action.payload.chrono,
            status:true,
            err: null, // Réinitialiser l'erreur
        } 
        case constants.GET_PAGES_ADMIN:
        return {
            ...state,
            pages:action.payload.pages,
            page:action.payload.page,
            size:action.payload.size,
            status:true,
            err: null, // Réinitialiser l'erreur
        } 
        case constants.GET_FACTURES_ADMIN:
        return {
            ...state,
            factures:action.payload.factures,
            status:true,
            err: null, // Réinitialiser l'erreur
        } 
        case constants.GET_ACTIVITIES_ADMIN:
        return {
            ...state,
            activities:action.payload.activities,
            page:action.payload.page,
            size:action.payload.size,
            status:true,
            err: null, // Réinitialiser l'erreur
        }
        case constants.GET_DISCIPLINE_ADMIN:
        return {
            ...state,
            discipline:action.payload.activities,
            status:true,
            err: null, // Réinitialiser l'erreur
        }  
    default:
            return state
    }
}