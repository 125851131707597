import { constants } from '../core/_constantsRedux/constants'

const initialState = {
promoters:null,
promoter:null,
err:null
}

export default function promotersReducer(state = initialState, action) {
switch (action.type) {
    case constants.GET_PROMOTERS:
        return {
            ...state,
            promoters:action.payload,
            err: null, // Réinitialiser l'erreur
        }
    case constants.GET_PROMOTER:
        return {
            ...state,
            promoter:action.payload,
            err: null, // Réinitialiser l'erreur
        }
    default:
    return state
    }
}