import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const languages = ['fr','nl','en','de']
i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "fr",
        debug: true,
        whitelist:languages,
        
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/translation.json",
        },
    });

export default i18n;
