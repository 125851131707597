import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { setLocalstorage } from '../localstorage/localStorage';
import { I_KIDDY, ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../_constants/constants';
import { Hoster } from './Hoster';

export const AxiosExec = axios.create();

const redirectToLogin = () => {
    localStorage.removeItem(I_KIDDY);
    window.location.href = "/login";
};

const getTokenInfo = () => {
    const storedData = localStorage.getItem(I_KIDDY) ? JSON.parse(localStorage.getItem(I_KIDDY)) : {};
    return {
        accessToken: storedData[ACCESS_TOKEN_NAME] || null,
        refreshToken: storedData[REFRESH_TOKEN_NAME] || null,
    };
};

const isTokenExpired = (token) => {
    if (!token) return true;
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000 < new Date().getTime();
};

const refreshAccessToken = async () => {
    const { refreshToken } = getTokenInfo();
    if (!refreshToken) {
        redirectToLogin();
        return null;
    }
    try {
        const configRefresh = {
            method: 'post',
            url: Hoster.path + 'refreshToken',
            headers: { 'Authorization': 'Bearer ' + refreshToken }
        };
        const response = await axios(configRefresh);
        if (response.data.status) {
            const newAccessToken = response.data.user.access_token;
            setLocalstorage(I_KIDDY, { ...response.data.user });
            return newAccessToken;
        }
    } catch (err) {
        redirectToLogin();
    }
    return null;
};

AxiosExec.interceptors.request.use(
    async (config) => {
        const { accessToken, refreshToken } = getTokenInfo();
        if (accessToken && refreshToken && isTokenExpired(accessToken)) {
            const newAccessToken = await refreshAccessToken();

            if (newAccessToken) {
                config.headers.Authorization = `Bearer ${newAccessToken}`;
            }
        } else if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

AxiosExec.interceptors.response.use(
    response => response,
    error => {
        if (!window.navigator.onLine) {
            console.error("Network Error: Check your internet connection.");
        } else if (error.response) {
            switch (error.response.status) {
                case 401: redirectToLogin(); break;
                case 500: console.error("Server Error: ", error.response); break;
                default: console.error("API Error: ", error.response);
            }
        } else if (error.request) {
            console.error("Network Error: ", error.request);
        } else {
            console.error("Error: ", error.message);
        }
        return Promise.reject(error);
    }
);
