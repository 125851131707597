import { constants } from '../core/_constantsRedux/constants'

const initialState = {
pages:null,
err:null
}

export default function favoritePagesReducer(state = initialState, action) {
switch (action.type) {
    case constants.GET_FAVOTITE_PAGES:
        return {
            ...state,
            pages:action.payload,
            err: null, // Réinitialiser l'erreur
        }
    default:
    return state
    }
}