import { constants } from '../core/_constantsRedux/constants'

const initialState = {
activity:null,
err:null
}

export default function activityUniqueReducer(state = initialState, action) {
switch (action.type) {
    case constants.GET_ACTIVITY_UNIQUE:
        return {
            ...state,
            activity:action.payload,
            err: null, // Réinitialiser l'erreur
        }
    default:
    return state
    }
}