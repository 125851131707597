import { constants } from '../core/_constantsRedux/constants'

const initialState = {
  adult:null,
  basket:null,
  chrono:null,
  totalSub:null,
  totalprice:null,
  totalReservation:null,
  promo:null,
  charges:null,
  autoValidation:false,
  err:null
}

export default function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_BASKET:
      return {
        // ...state,
        adult:action.payload.adult,
        basket: action.payload.basket,
        chrono:action.payload.chrono,
        totalSub:action.payload.totalSub,
        totalprice:action.payload.total,
        autoValidation:action.payload.autoValidation,
        totalReservation:action.payload.totalReservation,
        charges:action.payload.charges,
        promo:action.payload.promo,
        err: null, // Réinitialiser l'erreur
      }
      case constants.GET_BASKET_ERR:
      return {
        err: action.payload
      }
    default:
      return state
  }
}