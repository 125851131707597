import { constants } from '../core/_constantsRedux/constants'

const initialState = {
    countryList: null,
    err: null
}

export default function countryListReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_COUNTRY_LIST:
            return {
                // ...state,
                countryList: action.payload,
                err: null, // Réinitialiser l'erreur
            }
        default:
            return state
    }
}