import React, { Fragment } from "react";
import "./AppStyle.css";
import "./style.css";
import"./secondStyle.css"
import "bootstrap/dist/css/bootstrap.min.css"; // Importez le fichier CSS de Bootstrap ici
import 'bootstrap-icons/font/bootstrap-icons.css';
import RoutesPath from "../routes/Routes";


const App = () => {
    return (
        <Fragment>
            <div className="backGroundSite"/>
            <RoutesPath />
        </Fragment>
    );
};

export default App;
