import { constants } from '../core/_constantsRedux/constants'

const initialState = {
page:null,
listActvivtiesIng:null,
listActvivtiesCancel:null,
listActvivtiesRefund:null,
pagination:null,
size:null,
status:null,
err:null
}

export default function pageHostReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_PAGE_HOST:
            return {
                ...state,
                page:action.payload,
                pathNavigate:action.payload.path,
                err: null, // Réinitialiser l'erreur
            }
        case constants.GET_PAGE_HOST_ERR:
            return {
                ...state,
                err: action.payload
            }
        default:
        return state
    }
}