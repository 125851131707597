export const constants = {
    GET_USERS: 'GET_USERS',
    GET_USERS_ERR: 'GET_USERS_ERR',

    GET_CHRONOS: 'GET_CHRONOS',
    GET_CHRONOS_ERR: 'GET_CHRONOS_ERR',

    GET_PAGES_ADMIN: 'GET_PAGES_ADMIN',
    GET_PAGES_ADMIN_ERR: 'GET_PAGES_ADMIN_ERR',

    GET_ACTIVITIES_ADMIN: 'GET_ACTIVITIES_ADMIN',
    GET_ACTIVITIES_ADMIN_ERR: 'GET_ACTIVITIES_ADMIN_ERR',

    GET_DISCIPLINE_ADMIN: 'GET_DISCIPLINE_ADMIN',
    GET_DISCIPLINE_ADMIN_ERR: 'GET_DISCIPLINE_ADMIN_ERR',

    GET_FACTURES_ADMIN: 'GET_FACTURES_ADMIN',
    GET_FACTURES_ADMIN_ERR: 'GET_FACTURES_ADMIN_ERR',

    GET_USER: 'GET_USER',
    GET_COUNTRY_LIST:'GET_COUNTRY_LIST',
    EDITE_NAME_USER: 'EDITE_NAME_USER',
    GET_USER_ERR: 'GET_USER_ERR',

    GET_FACTURES: 'GET_FACTURES',
    GET_FACTURES_ERR: 'GET_FACTURES_ERR',

    GET_FACTURE_ITEM: 'GET_FACTURE_ITEM',
    GET_FACTURE_ITEM_ERR: 'GET_FACTURE_ITEM_ERR',

    GET_CANCEL_FACTURES: 'GET_CANCEL_FACTURES',
    GET_CANCEL_FACTURES_ERR: 'GET_CANCEL_FACTURES_ERR',

    GET_CANCEL_FACTURE_ITEM: 'GET_CANCEL_FACTURE_ITEM',
    GET_CANCEL_FACTURE_ITEM_ERR: 'GET_CANCEL_FACTURE_ITEM_ERR',

    GET_REPAYMENTS: 'GET_REPAYMENTS',
    GET_REPAYMENTS_ERR: 'GET_REPAYMENTS_ERR',

    GET_TOKEN: 'GET_TOKEN',

    GET_REPAYMENT_ITEM: 'GET_REPAYMENT_ITEM',
    GET_REPAYMENT_ITEM_ERR: 'GET_REPAYMENT_ITEM_ERR',

    GET_KID: 'GET_KID',
    GET_KID_ERR: 'GET_KID_ERR',

    GET_KIDS: 'GET_KIDS',
    GET_KIDS_ERR: 'GET_KIDS_ERR',

    ADD_KID: 'ADD_KID',
    ADD_KID_ERR: 'ADD_KID_ERR',

    UPDATE_KID: 'UPDATE_KID',
    UPDATE_KID_ERR: 'UPDATE_KID_ERR',

    DELETE_KID: 'DELETE_KID',
    DELETE_KID_ERR: 'DELETE_KID_ERR',

    ALERT_INFO: 'ALERT_INFO',
    ALERT_SUCCESS: 'ALERT_SUCCESS',
    ALERT_WARNING: 'ALERT_WARNING',
    ALERT_QUEUE: 'ALERT_QUEUE',
    ALERT_ERROR: 'ALERT_ERROR',
    ALERT_CLEAR: 'ALERT_CLEAR',

    SET_MESSAGE: 'SET_MESSAGE',
    CLEAR_MESSAGE: 'CLEAR_MESSAGE',

    GET_BASKET: 'GET_BASKET',
    GET_BASKET_ERR: 'GET_BASKET_ERR',

    GET_PROMOTERS: 'GET_PROMOTERS',
    GET_PROMOTERS_ERR: 'GET_PROMOTERS_ERR',

    GET_FAVOTITE_PAGES: 'GET_FAVOTITE_PAGES',
    GET_FAVOTITE_PAGES_ERR: 'GET_FAVOTITE_PAGES_ERR',

    GET_PROMOTER: 'GET_PROMOTER',
    GET_PROMOTER_ERR: 'GET_PROMOTER_ERR',

    GET_ACTIVITIES: 'GET_ACTIVITIES',
    GET_ACTIVITIES_ERR: 'GET_ACTIVITIES_ERR',

    GET_ACTIVITIES_BY_PROMOTER: 'GET_ACTIVITIES_BY_PROMOTER',
    GET_ACTIVITIES_BY_PROMOTER_ERR: 'GET_ACTIVITIES_BY_PROMOTER_ERR',

    GET_ACTIVITY: 'GET_ACTIVITY',
    GET_ACTIVITY_ERR: 'GET_ACTIVITY_ERR',

    GET_ACTIVITY_MARKS: 'GET_ACTIVITY_MARKS',
    GET_ACTIVITY_ERR_MARKS: 'GET_ACTIVITY_ERR_MARKS',

    GET_ACTIVITY_REMIND: 'GET_ACTIVITY_REMIND',
    GET_ACTIVITY_ERR_REMIND: 'GET_ACTIVITY_ERR_REMIND',

    GET_ACTIVITIES_SCHOOL: 'GET_ACTIVITIES_SCHOOL',
    GET_ACTIVITIES_SCHOOL_ERR: 'GET_ACTIVITIES_SCHOOL_ERR',

    GET_PAGES: 'GET_PAGES',
    GET_PAGES_ERR: 'GET_PAGES_ERR',

    POST_PAGES: 'POST_PAGES',
    POST_PAGES_ERR: 'POST_PAGES_ERR',

    GET_PAGE: 'GET_PAGE',
    GET_PAGE_ERR: 'GET_PAGE_ERR',

    GET_PAGE_HOST: 'GET_PAGE_HOST',
    GET_PAGE_HOST_ERR: 'GET_PAGE_HOST_ERR',

    GET_PAGE_ACTIVITIES: 'GET_PAGE_ACTIVITIES',
    GET_PAGE_ACTIVITIES_ERR: 'GET_PAGE_ACTIVITIES_ERR',

    GET_ACTIVITY_QUEUES:'GET_ACTIVITY_QUEUES',
    GET_ACTIVITY__ERR_QUEUES:'GET_ACTIVITY__ERR_QUEUES',


    GET_PAGE_ACTIVITIES_UNDISPLAY: 'GET_PAGE_ACTIVITIES_UNDISPLAY',
    GET_PAGE_ACTIVITIES_UNDISPLAY_ERR: 'GET_PAGE_ACTIVITIES_UNDISPLAY_ERR',

    GET_PAGE_ACTIVITIES_CLOSED: 'GET_PAGE_ACTIVITIES_CLOSED',
    GET_PAGE_ACTIVITIES_CLOSED_ERR: 'GET_PAGE_ACTIVITIES_CLOSED_ERR',

    GET_PAGE_ACTIVITY_CLOSED: 'GET_PAGE_ACTIVITY_CLOSED',
    GET_PAGE_ACTIVITY_CLOSED_ERR: 'GET_PAGE_ACTIVITY_CLOSED_ERR',

    GET_ACTIVITY_UNIQUE: 'GET_ACTIVITY_UNIQUE',
    GET_ACTIVITY_UNIQUE_ERR: 'GET_ACTIVITY_UNIQUE_ERR',

    GET_PAGE_ACTIVITY: 'GET_PAGE_ACTIVITY',
    GET_PAGE_ACTIVITY_ERR: 'GET_PAGE_ACTIVITY_ERR',

    GET_PAGE_FACTURES: 'GET_PAGE_FACTURES',
    GET_PAGE_FACTURES_ERR: 'GET_PAGE_FACTURES_ERR',

    GET_PAGE_FACTURE: 'GET_PAGE_FACTURE',
    GET_PAGE_FACTURE_ERR: 'GET_PAGE_FACTURE_ERR',

    GET_ACCREDITATION: 'GET_ACCREDITATION',
    GET_ACCREDITATION_ERR: 'GET_ACCREDITATION_ERR',

    GET_Navigate: 'GET_Navigate',
    CLEAR_Navigate: 'CLEAR_Navigate',
    CLEAR_Navigate_ERR: 'CLEAR_Navigate_ERR',
    GET_Navigate_ERR: 'GET_Navigate_ERR',

    GET_PAGE_CASHBACK: 'GET_PAGE_REMB',
};
