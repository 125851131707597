import { constants } from '../core/_constantsRedux/constants'

const initialState = {
    activities: null,
    err: null
}

export default function marksReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_ACTIVITY_MARKS:
            return {
                ...state,
                activities: action.payload,
                err: null, // Réinitialiser l'erreur
            }
        default:
            return state
    }
}