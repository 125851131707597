import { I_KIDDY, ACCESS_TOKEN_NAME } from "../_constants/constants"
import { getLocalstorage } from "../localstorage/localStorage"

const isAuthenticated = (needToBeAuth = true) => {

    function ACT(){
        if(getLocalstorage(I_KIDDY)) return getLocalstorage(I_KIDDY)[ACCESS_TOKEN_NAME]
        return getLocalstorage(I_KIDDY)
    } 

    const token = ACT()

    if (!token) {
        return !needToBeAuth;
    }
     
    return needToBeAuth;
};

export default isAuthenticated;
