import { constants } from '../core/_constantsRedux/constants'

const initialState = {
activities:null,
activity:null,
country:null,
activitiesByPromoter:null,
pathNavigateion:null,
err:null
}

export default function activitiesReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_ACTIVITIES:
            return {
                ...state,
                activities: action.payload,
                err: null, // Réinitialiser l'erreur
            }
        case constants.GET_ACTIVITIES_ERR:
        return {
            err: action.payload
        }
        case constants.GET_ACTIVITIES_BY_PROMOTER:
            return {
                ...state,
                activitiesByPromoter: action.payload,
                err: null, // Réinitialiser l'erreur
            }
        case constants.GET_ACTIVITIES_BY_PROMOTER_ERR:
            return {
                ...state,
                err: action.payload
            }
        case constants.GET_ACTIVITY:
            return {
                ...state,
                activity: action.payload,
                pathNavigateion:null,
                err: null, // Réinitialiser l'erreur

            }
        case constants.GET_Navigate:
            return {
                ...state,
                pathNavigateion: action.payload.pathNavigateion,
                err: null, // Réinitialiser l'erreur
            }
        case constants.GET_ACTIVITY_ERR:
            return {
                err: action.payload
            }
        default:
            return state
    }
}