
import { constants } from '../core/_constantsRedux/constants'
let user = JSON.parse(localStorage.getItem('i-kiddy'));

const initialState ={
    user,
    message:null,
    status:true,
    err:null,
    type:null
}
export default function userReducer(state = initialState, action){
    switch (action.type) {
        case constants.GET_USER:
            return {
                ...state,
                user:action.payload.user,
            } 
        case constants.EDITE_NAME_USER:
            return {
                ...state,
                user:action.payload.user,
                status:true,
                err: null, // Réinitialiser l'erreur
            } 
        case constants.GET_USER_ERR:
            return {
                ...state,
                status:false,
                err:action.payload
            }
        default:
            return state
    }
}