import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom"
import isAuthenticated from '../core/auth/isAuthenticated'
import { Spin } from "antd";
// const isAuthenticated = React.lazy(() => import('../core/auth/isAuthenticated'));
const Login = React.lazy(() => import("../pages/login/Login"));
const Register = React.lazy(() => import("../pages/register/Register"));
const Forgot = React.lazy(() => import("../pages/forgot/Forgot"));
const PricesAndServices = React.lazy(() => import("../pages/tarifs/PricesAndServices"));
const Privacy = React.lazy(() => import("../pages/legalTerms/Privacy"));
const Logout = React.lazy(() => import('../pages/logout/Logout'));
const NoMatch = React.lazy(() => import("../pages/noMatch/NoMatch"));
const Activation = React.lazy(() => import("../pages/activation/Activation"));
const InitializePwd = React.lazy(() => import("../pages/forgot/InitializePwd"));
const Promoters = React.lazy(() => import("../pages/promoters/Promoters"));
const Promoter = React.lazy(() => import("../pages/promoters/Promoter"));
const Activities = React.lazy(() => import("../pages/activities/Activities"));
const PageHome = React.lazy(() => import('../pages/promoterPage/PageHome'));
const InsertCodeAccreditation = React.lazy(() => import("../pages/accreditation/InsertCodeAccreditation"));
const Security = React.lazy(() => import("../pages/legalTerms/Security"));
const Marks = React.lazy(() => import('../pages/mark/Marks'));
const FavoritePages = React.lazy(() => import('../pages/mark/FavoriPage'));
const Jobs = React.lazy(() => import('../pages/jobs/Jobs'));
const Job = React.lazy(() => import('../pages/jobs/Job'));
const Contact = React.lazy(() => import('../pages/contact/Contact'));
const Help = React.lazy(() => import('../pages/help/Help'));
const Invoices = React.lazy(() => import("../pages/invoices/Invoices"));
const Home = React.lazy(() => import("../pages/landingPage/Home"));
const Queue = React.lazy(() => import("../pages/queue/Queue"));
const RemindAlarm = React.lazy(() => import("../pages/mark/RemindAlarm"));

const TermsOfUse = React.lazy(() => import("../pages/legalTerms/TermsOfUse"));

const RoutesPath = () => {

    const PrivateRoute = ({ children }) => {
        return isAuthenticated() ? children : <Navigate to="/login" />;
    };

    const PublicRoute = ({ children }) => {
        return isAuthenticated() ? <Navigate to="/activities/?tarif=0&tarif=10000" /> : children;
    };

    return (
        <Suspense fallback={<Spin size='small'/>}>
            <Routes>
                <Route path="/" element={<PublicRoute><Home /></PublicRoute>} />
                <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                <Route path="/register" element={<PublicRoute><Register /></PublicRoute>} />
                <Route path="/forgot" element={<PublicRoute><Forgot /></PublicRoute>} />
                <Route path="/landingPage" element={<PublicRoute><Home /></PublicRoute>} />
                <Route path="/description-services" element={<PublicRoute><PricesAndServices /></PublicRoute>} />
                <Route path="/privacy" element={<PublicRoute><Privacy /></PublicRoute>} />
                <Route path="/terms_of_use" element={<PublicRoute><TermsOfUse /></PublicRoute>} />
                <Route path="/security" element={<PublicRoute><Security /></PublicRoute>} />
                <Route path="/activation/:token/:id" element={<PublicRoute><Activation /></PublicRoute>} />
                <Route path="/initializePwd/:token/:id" element={<PublicRoute><InitializePwd /></PublicRoute>} />
                <Route path="/promoters" element={<PrivateRoute><Promoters /></PrivateRoute>} />
                <Route path="/promoter/:id" element={<PrivateRoute><Promoter /></PrivateRoute>} />
                <Route path="/activities" element={<PrivateRoute><Activities /></PrivateRoute>} />
                <Route path="/jobs" element={<PrivateRoute><Jobs /></PrivateRoute>} />
                <Route path="/job/:id" element={<PrivateRoute><Job /></PrivateRoute>} />
                <Route path="/Contact" element={<PrivateRoute><Contact /></PrivateRoute>} />
                <Route path="/Help" element={<PrivateRoute><Help /></PrivateRoute>} />
                <Route path="/facture/:act/:id/:tabs/:pagination" element={<PrivateRoute><Invoices /></PrivateRoute>} />
                <Route path="/insert-code-accreditation" element={<PrivateRoute><InsertCodeAccreditation /></PrivateRoute>} />
                <Route path="/favorite-activities" element={<PrivateRoute><Marks /></PrivateRoute>} />
                <Route path="/remind-alarm" element={<PrivateRoute><RemindAlarm/></PrivateRoute>} />
                <Route path="/queue" element={<PrivateRoute><Queue /></PrivateRoute>} />
                <Route path="/favorite-pages" element={<PrivateRoute><FavoritePages /></PrivateRoute>} />
                <Route path="/page-home" element={<PrivateRoute><PageHome /></PrivateRoute>} />
                <Route path="/logout" element={<PrivateRoute><Logout /></PrivateRoute>} />
                <Route path="*" element={<NoMatch />} />
            </Routes>
        </Suspense>
    );
}

export default RoutesPath;
