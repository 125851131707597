import { constants } from '../core/_constantsRedux/constants'

const initialState = {
    factures:null,
    factureItem:null,
    err: null
}

export default function facturesReducer(state = initialState, action) {
    switch (action.type) {
        case constants.GET_FACTURES:
            return {
                ...state,
                factures:action.payload,
                status:true,
                err: null, // Réinitialiser l'erreur
            }
        case constants.GET_FACTURE_ITEM:
            return {
                ...state,
                factureItem:action.payload,
                status:true,
                err: null, // Réinitialiser l'erreur
            }
        default:
            return state
    }
}