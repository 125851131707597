import { constants } from '../core/_constantsRedux/constants';

const initialState = {
  kids: null,
  kid: null,
  status: false,
  message: null,
  pathNavigate: null,
  tab:'info',
  err: null,
};

export default function kidReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_KIDS_ERR:
      return {
        ...state,
        status: false,
        err: action.payload,
      };
    case constants.GET_KIDS:
      return {
        ...state,
        status: true,
        kids: action.payload,
        err: null, // Réinitialiser l'erreur
      };
    case constants.GET_KID_ERR:
      return {
        ...state,
        status: action.payload.status,
        err: action.payload,
      };
    case constants.GET_KID:
      return {
        ...state,
        status: true,
        kid: action.payload.kid,
        err: null, // Réinitialiser l'erreur
      };
    case constants.UPDATE_KID_ERR:
      return {
        ...state,
        status: false,
        err: action.payload,
        message: action.payload.message || null,
        pathNavigate: null,
      };
    case constants.UPDATE_KID:
      return {
        ...state,
        status: true,
        kids: action.payload.kids,
        pathNavigate: action.payload.path,
        message: action.payload.message || null,
        err: null, // Réinitialiser l'erreur
      };
    case constants.ADD_KID_ERR:
      return {
        ...state,
        status: false,
        err: action.payload,
        message: action.payload.message || null,
        pathNavigate: null,
      };
    case constants.ADD_KID:
      return {
        ...state,
        status: true,
        kids: action.payload.kids,
        message: action.payload.message || null,
        pathNavigate: action.payload.path,
        err: null, // Réinitialiser l'erreur

      };
    case constants.GET_Navigate:
      return {
        ...state,
        pathNavigate: action.payload.pathNavigate,
        err: null, // Réinitialiser l'erreur
      };
    case constants.CLEAR_Navigate:
      return {
        ...state,
        pathNavigate: null,
      };
    default:
      return state;
  }
}
