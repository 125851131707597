import { combineReducers } from 'redux'
import userReducer from './user.reducer'
import kidReducer from './kid.reducer'
import alertReducer from './alert.reducer'
import messageReducer from './message.reducer'
import activitiesReducer from './activities.reducer'
import promotersReducer from './promoters.reducer'
import basketReducer from './basket.reducer'
import pagesReducer from './pages.reducer'
import marksReducer from './marks.reducer'
import favoritePagesReducer from './favoritePages.reducer'
import activityUniqueReducer from './activityUnique.reducer'
import adminReducer from './admin.reducer'
import remindsReducer from './remind.reducer'
import queuesReducer from './queues.reducer'
import pageHostReducer from './pageHost.reducer'
import facturesReducer from './factures.reducer'
import facturesCancelReducer from './facturesCancel.reducer'
import countryListReducer from './countryList.reducer'
// import notifications from './notifications.reducer'

export default combineReducers({
    userReducer,
    kidReducer,
    alertReducer,
    messageReducer,
    activitiesReducer,
    promotersReducer,
    basketReducer,
    pagesReducer,
    pageHostReducer,
    marksReducer,
    favoritePagesReducer,
    activityUniqueReducer,
    adminReducer,
    remindsReducer,
    queuesReducer,
    facturesReducer,
    facturesCancelReducer,
    countryListReducer,
    // ficalFileReducer
    // notifications
})