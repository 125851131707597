import { Badge } from "antd";

export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
    );
};

export const resetChronos = () => {
    return {
        type: 'RESET_CHRONOS'
    }
}


export const resetUsers = () => {
    return {
        type: 'RESET_USERS'
    }
}

export const resetAll = () => {
    return {
        type: 'RESET_ALL'
    }
}

export const overNumberDisplayPlus = (value, numb = 99) => {
    if (value > numb) return "+" + numb + ""
    return value
};

export const calculerAge = (dateNaissance) => {
    const dateActuelle = new Date();
    const dateNaissanceObj = new Date(dateNaissance);

    // Calcul de la différence en millisecondes entre la date actuelle et la date de naissance
    let difference = dateActuelle - dateNaissanceObj;

    // Conversion de la différence en années
    let age;
    age = Math.floor(difference / (1000 * 60 * 60 * 24 * 365.25));

    return age;
}

export const ajusterTableau = (tableau, type) => {
    const nombreElements = tableau.length;
    const reste = nombreElements % 3;
    const nombreAjouts = reste === 0 ? 0 : 3 - reste;

    for (let i = 0; i < nombreAjouts; i++) {
        tableau.push({ [type]: '.', addBlock: true });
    }

    return tableau;
}

export const ajusterTableau_ = (tableau, type) => {
    const nombreElements = tableau.length;
    const reste = nombreElements % 2;
    const nombreAjouts = reste === 0 ? 0 : 2 - reste;

    for (let i = 0; i < nombreAjouts; i++) {
        tableau.push({ [type]: '.', addBlock: true });
    }

    return tableau;
}
export const getClassName = (index, length) => {
    let className = 'checkbox_button_'
    let bottomLeft = length - 3
    if (index === 1) {
        className += ' last_first_line_item_R';
    }
    if (index === 2) {
        className += ' second_item';
    }
    if (index === bottomLeft) {
        className += ' last_lign_left_item';
    }
    if (index % 3 === 0) {
        className += ' left_items';
    } else if (index % 3 === 2) {
        className += ' right_items';
    } else {
        className += ' center_bottom_items';
    }

    // Colonne centrale
    if ((index + 1) % 3 === 1) {
        if (index === 0) {
            className += ' right_item';
        }
    }

    // Colonne de droite
    if ((index + 1) % 3 === 0) {
        if (index === length - 1) {
            className += ' last_item_R';
        }
    }


    return className;
}



export const abreviation = (parametre) => {
    const letters = parametre.substring(0, 1) + parametre.substring(parametre.indexOf(' ') + 1, parametre.indexOf(' ') + 2).toUpperCase();
    return letters;
}

export function deleteEntriesByIds(arr, ids) {
    return arr.filter(item => !ids.includes(item.id));
}

export function mergeKeepingOrder(oldArray, newArray, type, order = 'desc') {
    if (!oldArray || !newArray) {
        return oldArray || newArray || [];
    }

    const newArrayMap = newArray.reduce((map, item) => {
        map[item[type].id] = item;
        return map;
    }, {});

    // Met à jour les éléments existants avec les données du nouveau tableau et supprime ceux qui n'y sont pas
    const updatedItems = oldArray.map(item => newArrayMap[item[type].id]).filter(Boolean);

    // Trouve les éléments qui sont uniquement dans le nouveau tableau
    const newItems = newArray.filter(item => !updatedItems.includes(item));

    // Retourne la concaténation des éléments mis à jour et des nouveaux éléments par order
    if (order === 'desc') {
        return [...newItems, ...updatedItems];
    } else {
        return [...updatedItems, ...newItems];
    }
}


export function trierParCle(tableau, cle) {
    return tableau.sort((a, b) => {
        if (a[cle] < b[cle]) {
            return -1;
        } else if (a[cle] > b[cle]) {
            return 1;
        } else {
            return 0;
        }
    });
}

export function findActivityById(activitiesArray, id) {
    // Recherche dans le tableau activitiesArray l'élément dont l'id correspond
    const foundActivity = activitiesArray.find(activity => activity.id === id);

    // Retourne l'index activityForEdite si trouvé, sinon retourne undefined
    return foundActivity ? foundActivity.activityForEdite : undefined;
}

export function firstLetterToUpperWithDot(str) {
    if (!str || typeof str !== 'string') {
        return '';
    }
    return str.charAt(0).toUpperCase() + '.';
}

export function addLeadingZero(number, noIncrement = false, color=null) {
    // Si noIncrement est false, on incrémente number de 1
    if (!noIncrement) {
        number += 1;
    }

    return <span style={{color:color}}>{number < 10 ? '0' + number : number}</span>
}

function addZero(number) {
const newNumber =  number === null ? 0 : number
return newNumber < 10 ? '0' + newNumber : newNumber
}

export function countSub(subWait, Sub, subConfirme, total) {
   
   
    return  <div><Badge count={addZero(subWait)} showZero style={{color:'#580552', fontSize:10}} color='rgb(253, 233, 255)'/> <Badge count={addZero(Sub)} showZero style={{color:'#e0a40a', fontSize:10}} color='#fff5d9'/> <Badge count={addZero(subConfirme)} showZero style={{color:'#064305', fontSize:10}} color='#ddffdf'/> <Badge count={addZero(total)} showZero style={{color:'#5d5d5d', fontSize:10}} color='#ededed'/></div>
}

export function mToKm(meters) {
    const kilometers = meters / 1000;
    return `${kilometers.toFixed(1)}Km`;
}

export const convertSecondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours > 0) {
        return `${hours}h${minutes.toString().padStart(2, '0')}`;
    }
    return `${minutes}min`;
};



export function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  }

  export const flag = (code, country) => {
    return (
        <img
            src={`https://flagcdn.com/16x12/${code}.png`}
            srcSet={`https://flagcdn.com/32x24/${code}.png 2x,
                    https://flagcdn.com/48x36/${code}.png 3x`}
            width="16"
            height="12"
            alt={country}
        />
    );
};
  
  export const europeanCountries = [
    { country: 'albania', code: 'al', prefix: '+355' },
    { country: 'andorra', code: 'ad', prefix: '+376' },
    { country: 'austria', code: 'at', prefix: '+43' },
    { country: 'belarus', code: 'by', prefix: '+375' },
    { country: 'belgium', code: 'be', prefix: '+32' },
    { country: 'germany', code: 'de', prefix: '+49' },
    { country: 'bosnia and herzegovina', code: 'ba', prefix: '+387' },
    { country: 'bulgaria', code: 'bg', prefix: '+359' },
    { country: 'croatia', code: 'hr', prefix: '+385' },

    { country: 'cyprus', code: 'cy', prefix: '+357' },
    { country: 'czech republic', code: 'cz', prefix: '+420' },
    { country: 'denmark', code: 'dk', prefix: '+45' },
    { country: 'estonia', code: 'ee', prefix: '+372' },
    { country: 'finland', code: 'fi', prefix: '+358' },
    { country: 'france', code: 'fr', prefix: '+33' },
    { country: 'greece', code: 'gr', prefix: '+30' },
    { country: 'hungary', code: 'hu', prefix: '+36' },
    { country: 'iceland', code: 'is', prefix: '+354' },
    { country: 'ireland', code: 'ie', prefix: '+353' },
    { country: 'italy', code: 'it', prefix: '+39' },
    { country: 'latvia', code: 'lv', prefix: '+371' },
    { country: 'liechtenstein', code: 'li', prefix: '+423' },
    { country: 'lithuania', code: 'lt', prefix: '+370' },

    { country: 'luxembourg', code: 'lu', prefix: '+352' },
    { country: 'malta', code: 'mt', prefix: '+356' },
    { country: 'moldova', code: 'md', prefix: '+373' },
    { country: 'monaco', code: 'mc', prefix: '+377' },
    { country: 'montenegro', code: 'me', prefix: '+382' },
    { country: 'netherlands', code: 'nl', prefix: '+31' },
    { country: 'north macedonia', code: 'mk', prefix: '+389' },
    { country: 'norway', code: 'no', prefix: '+47' },
    { country: 'poland', code: 'pl', prefix: '+48' },
    { country: 'portugal', code: 'pt', prefix: '+351' },

    { country: 'romania', code: 'ro', prefix: '+40' },
    { country: 'san marino', code: 'sm', prefix: '+378' },
    { country: 'serbia', code: 'rs', prefix: '+381' },
    { country: 'slovakia', code: 'sk', prefix: '+421' },
    { country: 'slovenia', code: 'si', prefix: '+386' },
    { country: 'spain', code: 'es', prefix: '+34' },
    { country: 'sweden', code: 'se', prefix: '+46' },
    { country: 'switzerland', code: 'ch', prefix: '+41' },
    { country: 'ukraine', code: 'ua', prefix: '+380' },
    { country: 'united kingdom', code: 'gb', prefix: '+44' },
  ];
  

  export function validatePhoneNumber(prefix, phoneNumber) {
    switch (prefix) {
        case '+32': // Belgique
            return validateBelgiumPhoneNumber(phoneNumber);
        case '+33': // France
            return validateFrancePhoneNumber(phoneNumber);
        case '+49': // Allemagne
            return validateGermanyPhoneNumber(phoneNumber);
        case '+355': // Albanie
            return validateAlbaniaPhoneNumber(phoneNumber);
        case '+376': // Andorre
            return validateAndorraPhoneNumber(phoneNumber);
        case '+43': // Autriche
            return validateAustriaPhoneNumber(phoneNumber);
        case '+375': // Biélorussie
            return validateBelarusPhoneNumber(phoneNumber);
        case '+387': // Bosnie-Herzégovine
            return validateBosniaAndHerzegovinaPhoneNumber(phoneNumber);
        case '+359': // Bulgarie
            return validateBulgariaPhoneNumber(phoneNumber);
        case '+385': // Croatie
            return validateCroatiaPhoneNumber(phoneNumber);
        case '+357': // Chypre
            return validateCyprusPhoneNumber(phoneNumber);
        case '+420': // République tchèque
            return validateCzechRepublicPhoneNumber(phoneNumber);
        case '+45': // Danemark
            return validateDenmarkPhoneNumber(phoneNumber);
        case '+372': // Estonie
            return validateEstoniaPhoneNumber(phoneNumber);
        case '+358': // Finlande
            return validateFinlandPhoneNumber(phoneNumber);
        case '+30': // Grèce
            return validateGreecePhoneNumber(phoneNumber);
        case '+36': // Hongrie
            return validateHungaryPhoneNumber(phoneNumber);
        case '+354': // Islande
            return validateIcelandPhoneNumber(phoneNumber);
        case '+353': // Irlande
            return validateIrelandPhoneNumber(phoneNumber);
        case '+39': // Italie
            return validateItalyPhoneNumber(phoneNumber);
        case '+371': // Lettonie
            return validateLatviaPhoneNumber(phoneNumber);
        case '+423': // Liechtenstein
            return validateLiechtensteinPhoneNumber(phoneNumber);
        case '+370': // Lituanie
            return validateLithuaniaPhoneNumber(phoneNumber);
        case '+352': // Luxembourg
            return validateLuxembourgPhoneNumber(phoneNumber);
        case '+356': // Malte
            return validateMaltaPhoneNumber(phoneNumber);
        case '+373': // Moldavie
            return validateMoldovaPhoneNumber(phoneNumber);
        case '+377': // Monaco
            return validateMonacoPhoneNumber(phoneNumber);
        case '+382': // Monténégro
            return validateMontenegroPhoneNumber(phoneNumber);
        case '+31': // Pays-Bas
            return validateNetherlandsPhoneNumber(phoneNumber);
        case '+389': // Macédoine du Nord
            return validateNorthMacedoniaPhoneNumber(phoneNumber);
        case '+47': // Norvège
            return validateNorwayPhoneNumber(phoneNumber);
        case '+48': // Pologne
            return validatePolandPhoneNumber(phoneNumber);
        case '+351': // Portugal
            return validatePortugalPhoneNumber(phoneNumber);
        case '+40': // Roumanie
            return validateRomaniaPhoneNumber(phoneNumber);
        case '+378': // Saint-Marin
            return validateSanMarinoPhoneNumber(phoneNumber);
        case '+381': // Serbie
            return validateSerbiaPhoneNumber(phoneNumber);
        case '+421': // Slovaquie
            return validateSlovakiaPhoneNumber(phoneNumber);
        case '+386': // Slovénie
            return validateSloveniaPhoneNumber(phoneNumber);
        case '+34': // Espagne
            return validateSpainPhoneNumber(phoneNumber);
        case '+46': // Suède
            return validateSwedenPhoneNumber(phoneNumber);
        case '+41': // Suisse
            return validateSwitzerlandPhoneNumber(phoneNumber);
        case '+380': // Ukraine
            return validateUkrainePhoneNumber(phoneNumber);
        case '+44': // Royaume-Uni
            return validateUnitedKingdomPhoneNumber(phoneNumber);
        default:
            return 'Préfixe de pays non pris en charge';
    }
}


function validateBelgiumPhoneNumber(phoneNumber) {
    if (phoneNumber === null || phoneNumber === undefined) {
        return 'Numéro de téléphone manquant';
    }

    const phoneNumberStr = phoneNumber.toString();
    const normalizedNumber = phoneNumberStr
        .replace(/\s+/g, '') // Supprimer les espaces
        .replace(/^\+?32/, '') // Supprimer le code pays (+32 ou 32)
        .replace(/^0/, ''); // Supprimer le zéro initial

    // Expression régulière pour les numéros mobiles belges
    const mobileRegex = /^4\d{8}$/; // Doit commencer par 4 et suivre de 8 chiffres

    // Expression régulière pour les numéros fixes belges
    const landlineRegex = /^(1[0-9]|2|3|4|5[0-1]|6[0-7]|7[0-1]|8[0-9]|9)\d{6,7}$/;

    if (mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber)) {
        return true;
    }
    return 'Format incorrect';
}






function validateFrancePhoneNumber(phoneNumber) {
    // Vérifier si phoneNumber est null ou undefined
    if (phoneNumber === null || phoneNumber === undefined) {
        return 'Numéro de téléphone manquant';
    }

    // Convertir en chaîne de caractères et normaliser
    const phoneNumberStr = phoneNumber.toString();
    const normalizedNumber = phoneNumberStr
        .replace(/\s+/g, '') // Supprimer les espaces
        .replace(/^\+?33/, '') // Supprimer le code pays (+33 ou 33)
        .replace(/^0/, ''); // Supprimer le zéro initial

    // Expressions régulières pour les numéros mobiles et fixes en France
    const mobileRegex = /^(06|07)\d{8}$/; // Numéros mobiles
    const landlineRegex = /^(01|02|03|04|05)\d{8}$/; // Numéros fixes

    if (mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber)) {
        return true;
    }
    return 'Format incorrect';
}


function validateGermanyPhoneNumber(phoneNumber) {
    // Vérifier si phoneNumber est null ou undefined
    if (phoneNumber === null || phoneNumber === undefined) {
        return 'Numéro de téléphone manquant';
    }

    // Convertir en chaîne de caractères et normaliser
    const phoneNumberStr = phoneNumber.toString();
    const normalizedNumber = phoneNumberStr
        .replace(/\s+/g, '') // Supprimer les espaces
        .replace(/^\+?49/, '') // Supprimer le code pays (+49 ou 49)
        .replace(/^0/, ''); // Supprimer le zéro initial

    // Expressions régulières pour les numéros mobiles et fixes en Allemagne
    const mobileRegex = /^(015|016|017)\d{8}$/; // Numéros mobiles
    const landlineRegex = /^(02|03|04|05|06|07|08|09)\d{7,10}$/; // Numéros fixes

    if (mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber)) {
        return true;
    }
    return 'Format incorrect';
}


function validateAustriaPhoneNumber(phoneNumber) {
    if (phoneNumber === null || phoneNumber === undefined) {
        return 'Numéro de téléphone manquant';
    }

    const phoneNumberStr = phoneNumber.toString();
    const normalizedNumber = phoneNumberStr
        .replace(/\s+/g, '')
        .replace(/^\+?43/, '')
        .replace(/^0/, '');

    // Ajoutez les regex spécifiques à l'Autriche
    const mobileRegex = /^(6[4679]\d{7})$/;
    const landlineRegex = /^(1\d{7,12})$/;

    if (mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber)) {
        return true;
    }
    return 'Format incorrect';
}

function validateAndorraPhoneNumber(phoneNumber) {
    if (phoneNumber === null || phoneNumber === undefined) {
        return 'Numéro de téléphone manquant';
    }

    const phoneNumberStr = phoneNumber.toString();
    const normalizedNumber = phoneNumberStr
        .replace(/\s+/g, '')
        .replace(/^\+?376/, '')
        .replace(/^0/, '');

    // Ajoutez les regex spécifiques à Andorre
    const mobileRegex = /^(3[468]\d{5})$/;
    const landlineRegex = /^(7\d{5})$/;

    if (mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber)) {
        return true;
    }
    return 'Format incorrect';
}

function validateAlbaniaPhoneNumber(phoneNumber) {
    if (phoneNumber === null || phoneNumber === undefined) {
        return 'Numéro de téléphone manquant';
    }

    const phoneNumberStr = phoneNumber.toString();
    const normalizedNumber = phoneNumberStr
        .replace(/\s+/g, '')
        .replace(/^\+?355/, '')
        .replace(/^0/, '');

    // Ajoutez les regex spécifiques à l'Albanie
    const mobileRegex = /^(6[789]\d{7})$/;
    const landlineRegex = /^(4\d{7})$/;

    if (mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber)) {
        return true;
    }
    return 'Format incorrect';
}

function validateBelarusPhoneNumber(phoneNumber) {
    if (phoneNumber === null || phoneNumber === undefined) {
        return 'Numéro de téléphone manquant';
    }

    const phoneNumberStr = phoneNumber.toString();
    const normalizedNumber = phoneNumberStr
        .replace(/\s+/g, '')
        .replace(/^\+?375/, '')
        .replace(/^0/, '');

    const mobileRegex = /^(2[459]|33|44)\d{7}$/;
    const landlineRegex = /^(1[5-9]\d{7})$/;

    if (mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber)) {
        return true;
    }
    return 'Format incorrect';
}

function validateUnitedKingdomPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?44/, '').replace(/^0/, '');
    const mobileRegex = /^(7\d{9})$/;
    const landlineRegex = /^(1\d{9}|2\d{9})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateUkrainePhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?380/, '').replace(/^0/, '');
    const mobileRegex = /^(6[3-8]|9[1-9])\d{7}$/;
    const landlineRegex = /^(4[1234567]|5[123456789]|6[123456]|3[123456789])\d{6}$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}
function validateSwitzerlandPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?41/, '').replace(/^0/, '');
    const mobileRegex = /^(7[5-9]\d{7})$/;
    const landlineRegex = /^(2[1-9]|3[1-4]|4[1-3]|5[1-6]|6[1-2]|7[1-3]|8[1-6])\d{6}$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}
function validateSwedenPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?46/, '').replace(/^0/, '');
    const mobileRegex = /^(7[0236]\d{7})$/;
    const landlineRegex = /^(1[013-9]\d{6,7})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateSpainPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?34/, '').replace(/^0/, '');
    const mobileRegex = /^(6\d{8})$/;
    const landlineRegex = /^(8\d{8})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateSloveniaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?386/, '').replace(/^0/, '');
    const mobileRegex = /^(3[01]\d{6,7})$/;
    const landlineRegex = /^(1\d{6,7})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateSlovakiaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?421/, '').replace(/^0/, '');
    const mobileRegex = /^(9[012789]\d{6})$/;
    const landlineRegex = /^(2\d{7})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateSerbiaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?381/, '').replace(/^0/, '');
    const mobileRegex = /^(6[0-689]\d{6,7})$/;
    const landlineRegex = /^(1[0-8]\d{6,7})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateSanMarinoPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?378/, '').replace(/^0/, '');
    const phoneRegex = /^(5[1-8]\d{6})$/;  // Principalement des numéros fixes

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateRomaniaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?40/, '').replace(/^0/, '');
    const mobileRegex = /^(7[1-8]\d{7})$/;
    const landlineRegex = /^(2|3[1-6])\d{7}$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validatePortugalPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?351/, '').replace(/^0/, '');
    const mobileRegex = /^(9[1236]\d{7})$/;
    const landlineRegex = /^(2\d{8})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validatePolandPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?48/, '').replace(/^0/, '');
    const mobileRegex = /^(5[013-9]|6[069]|7[89]|8[8])\d{7}$/;
    const landlineRegex = /^(1[2-9]|2[2-5]|3[2-4]|4[1-4]|5[4-5]|6[1-4]|7[1-4]|8[1-4]|9[2-5])\d{7}$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateNorwayPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?47/, '').replace(/^0/, '');
    const phoneRegex = /^(4|9\d{7})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateNorthMacedoniaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?389/, '').replace(/^0/, '');
    const phoneRegex = /^(7[0125678]\d{6})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateNetherlandsPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?31/, '').replace(/^0/, '');
    const mobileRegex = /^(6[1-9]\d{7})$/;
    const landlineRegex = /^(1[0-9]|2[0-9]|3[0-9]|4[0-9]|5[0-9])\d{6}$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateMontenegroPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?382/, '').replace(/^0/, '');
    const phoneRegex = /^(6[78]\d{6})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateMonacoPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?377/, '').replace(/^0/, '');
    const phoneRegex = /^(4\d{8})$/;  // Format général pour mobile et fixe

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateMoldovaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?373/, '').replace(/^0/, '');
    const phoneRegex = /^(6\d{7})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateMaltaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?356/, '').replace(/^0/, '');
    const phoneRegex = /^(7[1-9]\d{6})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateLuxembourgPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?352/, '').replace(/^0/, '');
    const phoneRegex = /^(6\d{8})$/;  // Format général pour mobile et fixe

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateLithuaniaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?370/, '').replace(/^0/, '');
    const phoneRegex = /^(6\d{7})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateLiechtensteinPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?423/, '').replace(/^0/, '');
    const phoneRegex = /^(7\d{6,7})$/;  // Format général

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateLatviaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?371/, '').replace(/^0/, '');
    const phoneRegex = /^(2\d{7})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateItalyPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?39/, '').replace(/^0/, '');
    const mobileRegex = /^(3[1-9]\d{8})$/;
    const landlineRegex = /^(0[2-9]\d{8,9})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateIrelandPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?353/, '').replace(/^0/, '');
    const mobileRegex = /^(8[3-9]\d{7})$/;
    const landlineRegex = /^(1|2[1-4]|4[0-7]|5[0-9]|6[1-9]|7[1-5]|9[0-9])\d{7}$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateIcelandPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?354/, '').replace(/^0/, '');
    const phoneRegex = /^(7[1-9]|8[1-9])\d{5}$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateHungaryPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?36/, '').replace(/^0/, '');
    const phoneRegex = /^(2[0-9]|3[0-9]|4[0-9]|5[0-9]|6[0-9]|7[0-9]|8[0-9]|9[0-9])\d{6,7}$/;  // Format général

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateGreecePhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?30/, '').replace(/^0/, '');
    const mobileRegex = /^(6[9]\d{8})$/;
    const landlineRegex = /^(2[1-8]|21|231|232|233|234|251|261|262|263|264|265|271|272|273|274|275|281|282|283|284|289)\d{7}$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateFinlandPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?358/, '').replace(/^0/, '');
    const phoneRegex = /^(4[0-9]|50)\d{6,7}$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateEstoniaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?372/, '').replace(/^0/, '');
    const phoneRegex = /^(5\d{6,7})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateDenmarkPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?45/, '').replace(/^0/, '');
    const phoneRegex = /^(2[0-9]|3[1-9]|4[0-9]|5[1-9]|6[1-9]|7[1-9]|8[1-9])\d{6}$/;  // Format général

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateCzechRepublicPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?420/, '').replace(/^0/, '');
    const phoneRegex = /^(7[3-9]\d{7})$/;  // Format mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateCyprusPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?357/, '').replace(/^0/, '');
    const phoneRegex = /^(9[5-79]\d{6})$/;  // Format général pour mobile

    return phoneRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateBosniaAndHerzegovinaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?387/, '').replace(/^0/, '');
    const mobileRegex = /^(6[0-6]\d{6})$/;
    const landlineRegex = /^(3[1-5]\d{6})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateBulgariaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?359/, '').replace(/^0/, '');
    const mobileRegex = /^(8[7-9]\d{7})$/;
    const landlineRegex = /^(2\d{7}|[3-9]\d{6,7})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

function validateCroatiaPhoneNumber(phoneNumber) {
    if (!phoneNumber) return 'Numéro de téléphone manquant';

    const normalizedNumber = phoneNumber.toString().replace(/\s+/g, '').replace(/^\+?385/, '').replace(/^0/, '');
    const mobileRegex = /^(9[125789]\d{6})$/;
    const landlineRegex = /^(1\d{6,7}|[2-5]\d{6,7}|[7-8]\d{6,7})$/;

    return mobileRegex.test(normalizedNumber) || landlineRegex.test(normalizedNumber) ? true : 'Format incorrect';
}

export function findEntryById(entries, targetId) {
    return entries.find(entry => entry.kids_id === targetId);
}

export function negatifPositifColor(soldeRestant, total) {
    let colorClass;
    let textContent;

    if (soldeRestant > total) {
        colorClass = 'red';
        textContent = `Solde : € ${soldeRestant}`;
    } else if ((soldeRestant === 0 && total > 0) || (soldeRestant === 0 && total === 0)) {
        colorClass = 'green';
        textContent = `Solde: € ${soldeRestant}`;
    } else {
        colorClass = 'orange';
        textContent = `Montant perçu en trop : € ${Math.abs(soldeRestant)}`;
    }

    const divStyle = {
        color: colorClass,
    };

    return <>
        <b style={divStyle}>
            {textContent}
        </b>
    </>
}
