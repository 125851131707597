import React, { Suspense, useEffect } from "react";
import { createRoot } from 'react-dom/client';
import App from "./App/App";
import { Spin } from 'antd';
import "./core/i18n/i18n";
import 'antd/dist/reset.css';
import { Provider } from "react-redux";
import { getUser } from "./redux-actions/user.action";
import { getKids } from "./redux-actions/kid.action";
import { ConfigProvider } from 'antd'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux-reducer';

const store = configureStore({
  reducer: rootReducer,
});

const AppWrapper = () => {
    useEffect(() => {
        store.dispatch(getUser())
        store.dispatch(getKids())
    }, [])

    return (
        <Suspense fallback={(<div className='loadingOut'>
            <div className='loading'>
                <Spin size="large" /> Loarding
            </div>
        </div>)}>
            <Provider store={store}>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <ConfigProvider theme={{
                        token:{
                            colorPrimary:"#fa8c16"
                        }
                    }}>
                        <App />
                    </ConfigProvider>
                </QueryParamProvider>
            </Provider>
        </Suspense>
    )
}

createRoot(document.getElementById("root")).render(<Router><AppWrapper /></Router>);
