import { AxiosExec } from "../core/auth/AxiosExec";
import { Hoster } from "../core/auth/Hoster";
import { setLocalstorage, deleteLocalstorage } from "../core/localstorage/localStorage"
import { I_KIDDY } from "../core/_constants/constants"
import { constants } from '../core/_constantsRedux/constants'

export const login = (data) => {

    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: Hoster.path + 'login',
                data
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_USER,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.GET_USER_ERR,
                        payload: []
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: []
                    })
                    setLocalstorage(I_KIDDY, response.data.user)

                } else {
                    dispatch({
                        type: constants.GET_USER_ERR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data.message
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_USER_ERR,
                payload: err.message
            })
        }
    }
}

export const logout = (token) => {

    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: Hoster.path + 'logout',
                headers: { Authorization: `Bearer ${token}` }
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_USER,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_FACTURES,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_FACTURE_ITEM,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_REPAYMENTS,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_PROMOTER,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_PROMOTERS,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_BASKET,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_ACTIVITIES,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_ACTIVITY,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_ACTIVITIES_BY_PROMOTER,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_PAGES,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_PAGE,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_ACCREDITATION,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_CANCEL_FACTURES,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_CANCEL_FACTURE_ITEM,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_REPAYMENT_ITEM,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_KIDS,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_KID,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_PAGE_ACTIVITIES,
                        payload: []
                    })
                    dispatch({
                        type: constants.GET_PAGE_ACTIVITY,
                        payload: []
                    })
                    dispatch({
                        type: constants.ALERT_SUCCESS,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_SUCCESS,
                        payload: []
                    })
                    deleteLocalstorage(I_KIDDY)
                } else {
                    dispatch({
                        type: constants.GET_USER_ERR,
                        payload: []
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_USER_ERR,
                payload: err.message
            })
        }
    }
}


export const clear = () => {

    return async (dispatch) => {

        dispatch({
            type: constants.GET_USER,
            payload: []
        })
        dispatch({
            type: constants.GET_FACTURES,
            payload: []
        })
        dispatch({
            type: constants.GET_FACTURE_ITEM,
            payload: []
        })
        dispatch({
            type: constants.GET_REPAYMENTS,
            payload: []
        })
        dispatch({
            type: constants.GET_PROMOTER,
            payload: []
        })
        dispatch({
            type: constants.GET_PROMOTERS,
            payload: []
        })
        dispatch({
            type: constants.GET_BASKET,
            payload: []
        })
        dispatch({
            type: constants.GET_ACTIVITIES,
            payload: []
        })
        dispatch({
            type: constants.GET_ACTIVITY,
            payload: []
        })
        dispatch({
            type: constants.GET_ACTIVITIES_BY_PROMOTER,
            payload: []
        })
        dispatch({
            type: constants.GET_PAGES,
            payload: []
        })
        dispatch({
            type: constants.GET_PAGE,
            payload: []
        })
        dispatch({
            type: constants.GET_ACCREDITATION,
            payload: []
        })
        dispatch({
            type: constants.GET_CANCEL_FACTURES,
            payload: []
        })
        dispatch({
            type: constants.GET_CANCEL_FACTURE_ITEM,
            payload: []
        })
        dispatch({
            type: constants.GET_REPAYMENT_ITEM,
            payload: []
        })
        dispatch({
            type: constants.GET_KIDS,
            payload: []
        })
        dispatch({
            type: constants.GET_KID,
            payload: []
        })
        dispatch({
            type: constants.GET_PAGE_ACTIVITIES,
            payload: []
        })
        dispatch({
            type: constants.GET_PAGE_ACTIVITY,
            payload: []
        })
        dispatch({
            type: constants.ALERT_SUCCESS,
            payload: []
        })
        dispatch({
            type: constants.ALERT_SUCCESS,
            payload: []
        })
    }
}

export const getUser = (token) => {
    return async (dispatch) => {
        if (token) {
            try {
                const config = {
                    method: 'get',
                    url: Hoster.path + 'user-profile-parent',
                    headers: { Authorization: `Bearer ${token}` }
                }
                await (AxiosExec(config)).then(response => {

                    if (response.data.status) {
                        dispatch({
                            type: constants.GET_USER,
                            payload: response.data
                        })
                    } else {
                        dispatch({
                            type: constants.GET_USER_ERR,
                            payload: response.data
                        })
                    }
                })
            } catch (err) {
                dispatch({
                    type: constants.GET_USER_ERR,
                    payload: err.message
                })
            }
        }
    }
}

export const editetUserName = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: Hoster.path + 'user-profile-parent',
                headers: { Authorization: `Bearer ${token}` }
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.EDITE_NAME_USER,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_SUCCESS,
                        payload: response.data
                    })
                } else {
                    dispatch({
                        type: constants.GET_USER_ERR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_USER_ERR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
        }
    }
}


export const getFactures = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: Hoster.path + 'listFactures?page=' + data.page,
                headers: { Authorization: `Bearer ${token}` }
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_FACTURES,
                        payload: response.data.factures
                    })
                } else {
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_FACTURES_ERR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
        }
    }
}

export const getFactureItem = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: Hoster.path + 'itemFacture?reference=' + data.reference,
                headers: { Authorization: `Bearer ${token}` }
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_FACTURE_ITEM,
                        payload: response.data.factureItem
                    })
                } else {
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_FACTURE_ITEM_ERR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
        }
    }
}


export const getFacturesCancel = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: Hoster.path + 'listFacturesCancel?page=' + data.page,
                headers: { Authorization: `Bearer ${token}` }
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_CANCEL_FACTURES,
                        payload: response.data.facturesCancel
                    })
                } else {
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_FACTURE_ITEM_ERR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
        }
    }
}


export const getFactureCancelItem = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: Hoster.path + 'itemFacture?reference=' + data.reference,
                headers: { Authorization: `Bearer ${token}` }
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_CANCEL_FACTURE_ITEM,
                        payload: response.data.factureItem
                    })
                } else {
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_FACTURE_ITEM_ERR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
        }
    }
}


export const getRepayment = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: Hoster.path + 'listFacturesRefund?page=' + data.page,
                headers: { Authorization: `Bearer ${token}` }
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_REPAYMENTS,
                        payload: response.data.repayments
                    })
                } else {
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_REPAYMENTS_ERR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
        }
    }
}


export const getRepaymentItem = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: Hoster.path + 'itemFacture?reference=' + data.reference,
                headers: { Authorization: `Bearer ${token}` }
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_REPAYMENT_ITEM,
                        payload: response.data.factureItem
                    })
                } else {
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_REPAYMENT_ITEM_ERR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: []
            })
        }
    }
}