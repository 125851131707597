import { constants } from '../core/_constantsRedux/constants'

const initialState = {
    type:null,
    title:null,
    message:null,
    labelBtn:null,
    pathNavigate:null,
    err:null
}

export default function messageReducer(state = initialState, action) {
    switch (action.type) {
        case constants.SET_MESSAGE:
        return {
            type: action.payload.type,
            title: action.payload.title ? action.payload.title :null,
            message: action.payload.message,
            labelBtn:action.payload.labelBtn ? action.payload.labelBtn : null,
            pathNavigate:action.payload.pathNavigate ? action.payload.pathNavigate :null,
            pathChoose:action.payload.pathChoose ? action.payload.pathChoose :false,
            err: null, // Réinitialiser l'erreur
        }
        case constants.CLEAR_MESSAGE:
        return {};
        default:
        return state
    }
}