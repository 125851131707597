import { AxiosExec } from "../core/auth/AxiosExec";
import { Hoster } from "../core/auth/Hoster";
import { isEmpty } from "../core/functions/Utils";
import { constants } from '../core/_constantsRedux/constants'



export const getKids = (token) => {
    return async (dispatch) => {
        if (token) {
            try {
                const config = {
                    method: 'get',
                    url: Hoster.path + 'profile-kid',
                    headers: { Authorization: `Bearer ${token}` }
                }
                await (AxiosExec(config)).then(response => {
                    if (isEmpty(response.data)) {
                        dispatch({
                            type: constants.GET_KIDS_ERR,
                            payload: response.data
                        })
                        dispatch({
                            type: constants.GET_KIDS,
                            payload: []
                        })
                    } else {
                        dispatch({
                            type: constants.GET_KIDS,
                            payload: response.data
                        })
                        if(response.data.length > 0){
                            dispatch({
                                type: constants.GET_KID,
                                payload: {kid:response.data[0]}
                            }) 
                        }
                    }
                })
            } catch (err) {
                dispatch({
                    type: constants.GET_KIDS_ERR,
                    payload: err.message
                })
            }
        }
    }
}

export const getKid = (id, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'get',
                url: Hoster.path + 'kid_profile?kids_id=' + id,
                headers: { Authorization: `Bearer ${token}` },
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_KID,
                        payload: response.data
                    })
                } else {
                    dispatch({
                        type: constants.GET_KID_ERR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.GET_KID_ERR,
                payload: err.message
            })
        }
    }
}

export const addKid = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'post',
                url: Hoster.path + 'addkid',
                headers: { Authorization: `Bearer ${token}` },
                data
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.ADD_KID,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.CLEAR_Navigate,
                        payload: response.data
                    })
                } else {
                    dispatch({
                        type: constants.ADD_KID_ERR,
                        payload: response.data
                    })
                }
            })
        } catch (err) {
            dispatch({
                type: constants.ADD_KID_ERR,
                payload: err.message
            })
        }
    }
}

export const updateKid = (data, token) => {

    return async (dispatch) => {
        try {
            const config = {
                method: 'put',
                url: Hoster.path + 'kid_update',
                headers: { Authorization: `Bearer ${token}` },
                data
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.UPDATE_KID,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.CLEAR_Navigate,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_SUCCESS,
                        payload: response.data
                    })
                    
                } else {
                    dispatch({
                        type: constants.UPDATE_KID_ERR,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                    
                }
            })
        } catch (err) {
            dispatch({
                type: constants.UPDATE_KID_ERR,
                payload: err.message
            })
        }
    }
}

export const deleteKid = (data, token) => {
    return async (dispatch) => {
        try {
            const config = {
                method: 'delete',
                url: Hoster.path + 'deleteKid',
                headers: { Authorization: `Bearer ${token}` },
                data
            }
            await (AxiosExec(config)).then(response => {
                if (response.data.status) {
                    dispatch({
                        type: constants.GET_Navigate,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.CLEAR_Navigate,
                        payload: response.data
                    })
                    dispatch({
                        type: constants.ALERT_SUCCESS,
                        payload: response.data
                    })
                    
                } else {
                    dispatch({
                        type: constants.ALERT_ERROR,
                        payload: response.data
                    })
                    
                }
            })
        } catch (err) {
            dispatch({
                type: constants.UPDATE_KID_ERR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_ERROR,
                payload: err.message
            })
            dispatch({
                type: constants.ALERT_CLEAR,
                payload: err.message
            })
        }
    }
}